import React,{useState,useEffect} from 'react'

import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    Divider,
    ListItemIcon,
    ListItemText,
    IconButton,
    Avatar,
    Popover,
    Paper,
    MenuItem
} from "@mui/material";

//Auth
import {domain} from "../../config";

import { useHistory } from "react-router-dom";
import { useAuth } from '../../contexts/AuthContext';

import {makeStyles} from '@mui/styles';

// Import Material Icon
import SearchIcon from '@mui/icons-material/Search';
import LogoutIcon from '@mui/icons-material/Logout';

// Import Custom Icon
import { Icon } from '@iconify/react';
import usersIcon from '@iconify/icons-heroicons-solid/users';
import bxsBell from '@iconify/icons-bx/bxs-bell';
import settingsSolid from '@iconify/icons-clarity/settings-solid';
import bxsUserCircle from '@iconify/icons-bx/bxs-user-circle';
import arrowRightLeft from '@iconify/icons-akar-icons/arrow-right-left';

const drawerWidth = 280;
const useStyles = makeStyles({
    button_header: {
        margin: "0px 0px 0px 8px!important"
    },
    popover_paper: {
        width: "300px!important",
    }
});

export default function MyAppBar() {
    const classes = useStyles();

    //Log out
    // const [error, setError] = useState('');
    const {currentUser, logout} = useAuth();
    const paramUid = "?uid="+(currentUser.uid) + "&email="+(currentUser.email);

    const history = useHistory();
    
    async function handleLogout() {
        // setError("");
        try {
            await logout();
            console.log("logout done")
            
            history.push("/login")

        } catch {
            console.log("Failed to log out");
            // setError("Failed to log out");
        }
    }

    const [roleMember, setRoleMember] = React.useState("Guest");

    //--Log out

    // Popover
    const [anchorElPopover, setAnchorElPopover] = useState(null);

    const handleClickPopover = (event) => {
        setAnchorElPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setAnchorElPopover(null);
    };

    const openPopover = Boolean(anchorElPopover);
    const idPopover = openPopover ? 'simple-popover' : undefined;

    //--Popover

    //--GET User
    const getMemberRole = async () => {
        await fetch(
          domain+"/getMemberRole"+paramUid,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
            },
            
          }
        )
          .then(response => {
            return response.json();
          })
          .then(data => {
            setRoleMember(data["role"]);
          });
      };
    
    useEffect(() => {
    getMemberRole();
    }, []);
     //--GET User

    return (
        <AppBar
            position="fixed"
            sx={{
                "@media (min-width: 600px)": {
                    width: `calc(100% - ${drawerWidth}px)`, 
                },
                "@media (max-width: 600px)": {
                },
                ml: `${drawerWidth}px`, 
                backgroundColor: "rgb(255, 255, 255)" 
            }}
        >
            <Toolbar>
                <Box sx={{ flexGrow: 1 }} />
                <IconButton aria-label="Search" size="small" className={classes.button_header}>
                    <SearchIcon />
                </IconButton>
                <IconButton aria-label="Contact" size="small" className={classes.button_header}>
                    <Icon icon={usersIcon} />
                </IconButton>
                <IconButton aria-label="Notification" size="small" className={classes.button_header}>
                    <Icon icon={bxsBell} />
                </IconButton>
                <IconButton aria-label="Notification" aria-describedby={idPopover} size="small" className={classes.button_header} onClick={handleClickPopover}>
                    <Avatar
                        alt="Remy Sharp"
                        src="https://mui.com/static/images/avatar/1.jpg"
                    />
                </IconButton>
                <Popover
                    id={idPopover}
                    open={openPopover}
                    anchorEl={anchorElPopover}
                    onClose={handleClosePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    className={classes.popover_paper}
                >
                    <Paper elevation={16} className={classes.popover_paper}>
                        <Box sx={{
                            alignItems: "center",
                            padding: "16px",
                            display: "flex",
                        }}>
                            <Avatar
                                // alt="Remy Sharp"
                                src="https://mui.com/static/images/avatar/1.jpg"
                            />
                            <Box sx={{
                                ml: "8px"
                            }}>
                                <Typography variant="body1" sx={{color: "rgb(18, 24, 40)", textTransform: "lowercase"}}>{currentUser && currentUser.email}</Typography>
                                <Typography variant="body2" sx={{color: "rgb(101, 116, 139)"}}>{roleMember}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                        <Box sx={{
                            marginTop: "8px",
                            marginBottom: "8px",
                            "& svg": {
                                width: "1.45em",
                                height: "1.45em",
                            }
                        }}>
                            <MenuItem component="a">
                                <ListItemIcon>
                                    <Icon icon={bxsUserCircle} />
                                </ListItemIcon>
                                <ListItemText>Profiles</ListItemText>
                            </MenuItem>
                            <MenuItem component="a">
                                <ListItemIcon>
                                    <Icon icon={settingsSolid} />
                                </ListItemIcon>
                                <ListItemText>Settings</ListItemText>
                            </MenuItem>
                            <MenuItem component="a">
                                <ListItemIcon>
                                    <Icon icon={arrowRightLeft} />
                                </ListItemIcon>
                                <ListItemText>Change organization</ListItemText>
                            </MenuItem>
                        </Box>

                        <Divider />
                        <Box sx={{
                            marginTop: "8px"
                        }}>
                            <MenuItem component="a" sx={{paddingBottom: "14px"}} onClick={handleLogout}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText>Logout</ListItemText>
                            </MenuItem>
                        </Box>
                    </Paper>
                </Popover>
            </Toolbar>

            
        </AppBar>
    )
}
