import React, {
  useState
} from "react";
import {
  makeStyles
} from "@mui/styles";

//Import material components
import {
  Drawer,
  Divider,
  Button,
  List,
  ListItem,
  ListSubheader,
  Collapse,
  ListItemText,
  ListItemButton,

  Link
} from "@mui/material";


import {
  Link as RouterLink
} from 'react-router-dom';


//Import material icon
import LocalMallIcon from "@mui/icons-material/LocalMall";
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

//Import custom icon
import {
  Icon
} from "@iconify/react";
import etsyIcon from '@iconify/icons-cib/etsy';
import amazonCircleFilled from "@iconify/icons-ant-design/amazon-circle-filled";
import logoFacebook from "@iconify/icons-ion/logo-facebook";

const dataIndexName = {
  Shopee: [

    {
      text: "Fulfillment",
      path: "/",
      index: 0,
      icon: < LocalShippingIcon / >,
        child: [
          {
            text: "Scanner",
            path: "/fulfill/scanner",
            value: "Shopee_Scanner",
            index: -2,
            parent: "Fulfillment"
          },
          {
            text: "Scanner Camera",
            path: "/fulfill/scanner_camera",
            value: "Shopee_Scanner_Camera",
            index: -2,
            parent: "Fulfillment"
          }
        ]
    }
  ]
};

const usEtsyles = makeStyles({
  category: {
    color: "rgb(209, 213, 219)!important",

    borderRadius: "8px!important",
    padding: "9px 24px!important",
    justifyContent: "flex-start!important",
    fontWeight: "700!important",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)!important"
    }
  },
  category_select: {
    color: "rgb(16, 185, 129)!important",
    // backgroundColor: "rgba(255, 255, 255, 0.08)!important",

    borderRadius: "8px!important",
    padding: "9px 24px!important",
    justifyContent: "flex-start!important",
    fontWeight: "700!important",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)!important"
    }
  },

  child_category: {
    color: "rgb(209, 213, 219)!important",

    borderRadius: "8px!important",
    padding: "9px 24px!important",
    justifyContent: "flex-start!important",
    fontWeight: "700!important",
    width: "200px",
    marginBottom: "4px",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08)!important"
    }
  },
  child_category_select: {
    color: "rgb(16, 185, 129)!important",
    backgroundColor: "rgba(255, 255, 255, 0.08)!important",

    borderRadius: "8px!important",
    padding: "9px 24px!important",
    justifyContent: "flex-start!important",
    fontWeight: "700!important",
    width: "200px",
    marginBottom: "4px"
  }
});

const drawerWidth = 280;
export default function NavBar() {
  const classes = usEtsyles();
  const [selectedParent, setSelectedParent] = React.useState("Shopee");
  const [selectedChild, setSelectedChild] = React.useState("Shopee_Orders");
  const handleListItemClickChild = (event, child_item) => {
    console.log(child_item);
    setSelectedChild(child_item.value);
    setSelectedParent(child_item.parent);
  };

  const handleListItemClick = (event, item) => {
    console.log("handleListItemClick",item.text);
    setSelectedParent(item.text);
  };

  //
  const handleClick = index => {
    setState(prevState => ({
      ...prevState,
      [index]: !state[index]
    }));
  };
  //
  const [state, setState] = useState({
    "Shopee": true,
    "Design": false,
    "Send Supplier": false,
    "Fulfillment": false
  });

  //

  return ( 
    <Drawer 
      sx = {{
        "@media (max-width: 600px)": {
          width: 0,
          "& .MuiDrawer-paper": {
            width: 0,
            boxSizing: "border-box",
            backgroundColor: "rgb(17, 24, 39)"
          },
        },
        "@media (min-width: 600px)": {
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            backgroundColor: "rgb(17, 24, 39)"
          },
        },
        // width: drawerWidth,

        

        flexShrink: 0,
        "& .MuiDivider-root": {
          m: "24px 0px",
          flexShrink: 0,
          borderWidth: "0px 0px thin",
          borderStyle: "solid",
          borderColor: "rgb(45, 55, 72)"
        },
        "& .MuiListSubheader-root": {
          backgroundColor: "#fff0",
          color: "rgb(107, 114, 128)",
          fontWeight: 700,
          textTransform: "uppercase",
          p: 0,
          ml: "32px",
          boxSizing: "border-box",
          listStyle: "none",
          fontSize: "0.75rem",
          lineHeight: 2.5
        }
    }}
      variant = "permanent"
      anchor = "left"
    >
    <Divider / >
    <ListSubheader > Shopee </ListSubheader>
    <List sx = {{p: 0}}>
      {
        dataIndexName["Shopee"].map((item, index) => (
          <ListItem 
            sx = {{
              p: "0px 16px",
              mb: "4px",
              textAlign: "left",
              display: "inherit"
            }}
            key = { item["value"]}
          >
          <Button 
            component = "a"
            size = "medium"
            sx = {{ textTransform: "capitalize"}}
            fullWidth 
            disableElevation className = {
              `${
                selectedParent === item.text
                  ? classes.category_select
                  : classes.category
              }`
            }
            onClick = {
              event => {
                if (item.child.length > 0) {
                    handleClick(item.text);
                    // handleListItemClick(event, item);
                } else {
                    // handleListItemClick(event, item);
                }
              }
            }
            startIcon = {item.icon}
          >

            {
              item.text
            } 
          </Button> 

          {
            item.child.length > 0 ? ( 
              <Collapse in = { state[item.text] } timeout = "auto" unmountOnExit >
                <List sx = {{p: 0}} >
                  <ListItem sx = {{ pl: 4, display: "grid"}} > 
                  
                    {
                      item.child.map((child_item, child_index) => (

                        <Link component={ RouterLink} to={child_item.path} key={child_item.value} underline="none" >
                          <Button 
                            className = {
                              `${
                                  selectedChild === child_item.value
                                  ? classes.child_category_select
                                  : classes.child_category
                              }`
                            }
                            onClick = {
                              event => {
                                handleListItemClickChild(event, child_item);
                              }
                            }
                            component = "a"
                            size = "medium"
                            sx = {{textTransform: "capitalize"}}
                            fullWidth 
                            disableElevation
                            key = {child_item.value}
                          >
                            {child_item.text}
                          
                          </Button>
                          
                        </Link>
                      ))
                    } 
                  </ListItem>
                </List>
              </Collapse>
            ) : null
          } 
        </ListItem>
        ))
      } 
    </List>
    </Drawer>
  );
}