import React from "react";
import ReactToPrint from "react-to-print";

import {Grid, Button} from '@mui/material';

import {domain} from "../../../../config";

class ComponentToPrint extends React.Component {
  render(props) {
    return (
      <img
        src={this.props.shipping_label}
        alt="Logo"
        style={{
            "marginLeft": "auto",
            "marginRight": "auto",
        }}
      />
    );
  }
}

class PrintShippingLabel extends React.Component {
  render() {
    const updateOrderStatus = async () => {
        console.log("updateOrderStatus");
        await fetch(
          domain+"/updateOrderStatus",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify({"order_sn": this.props.order_sn, "status": 4})
          }
        )
          .then(response => {
            return response.json();
          })
          .then(data => {
            console.table("Done");
          });
      };

    return (
      <div>
        <Grid container spacing={3} sx={{marginTop: 0}} >
            <Grid item xs={12} md={12}>
                <ReactToPrint 
                  onAfterPrint={updateOrderStatus} 
                  trigger={() => <Button variant="contained" fullWidth href="#">Print</Button>}
                  content={() => this.componentRef}
                />
            </Grid>
        </Grid>
        
        <ComponentToPrint
            ref={el => (this.componentRef = el)}
            shipping_label={this.props.shipping_label}
            sx={{"marginLeft": "auto","marginRight": "auto"}}
        />
      </div>
    );
  }
}

export default PrintShippingLabel;
