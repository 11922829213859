import * as React from "react";
import {
    Box,
    CssBaseline,
    Toolbar,
} from "@mui/material";
import { SnackbarProvider, useSnackbar } from 'notistack';

import MyAppBar from "./Frame/MyAppBar";
import MyNavBar from "./Frame/NavBar";

import { Route, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import AuthProvider from "../contexts/AuthContext";

// Shopee
import Shopee_import_products_new from "./Pages/Shopee/Import_Products_New/Shopee_import_products_new";
import Shopee_shop from "./Pages/Shopee/Shop/Shopee_shop";
import Shopee_dashboard from "./Pages/Shopee/Dashboard/Shopee_dashboard";
import Shopee_orders from "./Pages/Shopee/Orders/Shopee_orders";
import Shopee_OrderDetail from "./Pages/Shopee/OrderDetail/Shopee_OrderDetail";
import Shopee_account from "./Pages/Shopee/Account/Shopee_Account";
import Shopee_product from "./Pages/Shopee/Product/Shopee_Product";
import Shopee_create_orders from "./Pages/Shopee/OrderCreate/Shopee_Create_Order";

//Design
import Shopee_design from "./Pages/Shopee/Design/Shopee_design";
import Shopee_design_mica from "./Pages/Shopee/DesignMica/Shopee_design_mica";
import Shopee_design_phonecase from "./Pages/Shopee/DesignPhoneCase/Shopee_design_phonecase";
import Shopee_face_cut from "./Pages/Shopee/FaceCut/Shopee_face_cut";

//Send Supplier
import Shopee_mica_pod from "./Pages/Shopee/MicaPod/Shopee_mica_pod";
import Shopee_mica_custom from "./Pages/Shopee/MicaCustom/Shopee_mica_custom";
import Shopee_phone_case_pod from "./Pages/Shopee/PhoneCasePod/Shopee_phone_case_pod";
import Shopee_phone_case_custom from "./Pages/Shopee/PhoneCaseCustom/Shopee_phonecase_custom";


//Fulfill
import Shopee_scanner from "./Pages/Shopee/Scanner/Shopee_scanner";
import Shopee_ScannerCamera from "./Pages/Shopee/ScannerCamera/Shopee_scanner";


// Amazon Seller
import Amazon_seller_tagging_title from "./Pages/Shopee/Orders/Shopee_orders";


// orders
const PageLists = [
    // Shopee
    // {parent: "/shopee", path: "/import_products/new", component: Shopee_import_products_new},
    // {parent: "/shopee", path: "/shop", component: Shopee_shop},
    // {parent: "/shopee", path: "/orders", component: Shopee_orders},
    // {parent: "/shopee", path: "/create_order", component: Shopee_create_orders},
    
    // {parent: "/shopee", path: "/orders/:id", component: Shopee_OrderDetail},

    // {parent: "/shopee", path: "/dashboard", component: Shopee_dashboard},
    // {parent: "/shopee", path: "/account", component: Shopee_account},
    // {parent: "/shopee", path: "/product", component: Shopee_product},

    // //Design
    // {parent: "/design", path: "/design", component: Shopee_design},
    // {parent: "/design", path: "/design_mica", component: Shopee_design_mica},
    // {parent: "/design", path: "/design_phone_case", component: Shopee_design_phonecase},
    // {parent: "/design", path: "/face_cut", component: Shopee_face_cut},

    // //Send Supplier
    // {parent: "/send_supplier", path: "/mica_pod", component: Shopee_mica_pod},
    // {parent: "/send_supplier", path: "/mica_custom", component: Shopee_mica_custom},
    // {parent: "/send_supplier", path: "/phone_case_pod", component: Shopee_phone_case_pod},
    // {parent: "/send_supplier", path: "/phone_case_custom", component: Shopee_phone_case_custom},

    //Fulfill
    {parent: "/fulfill", path: "/scanner", component: Shopee_scanner},
    {parent: "/fulfill", path: "/scanner_camera", component: Shopee_ScannerCamera},
]
const drawerWidth = 280;

export default function PermanentDrawerLeft() {
    return (

        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <MyAppBar />
            {/* Drawer */}

            <MyNavBar />
            <Box
                component="main"
                sx={{
                    "@media (max-width: 600px)": {
                        flexGrow: 1, 
                        bgcolor: "#F9FAFC", 
                        p: 0, 
                        paddingTop: "40px" , 
                    },
                    "@media (min-width: 600px)": {
                        flexGrow: 1, 
                        bgcolor: "#F9FAFC", 
                        p: 0, 
                        paddingTop: "40px" , 
                        maxWidth: `calc(100vw - ${drawerWidth}px)`
                    }
                }}
            >
                <Toolbar />
                <AuthProvider>
                    <Switch>
                        {PageLists.map((item,index) => (
                            <Route exact path={item.parent+item.path} component={item.component} key={index} />
                        ))}
                    </Switch>
                </AuthProvider>
            </Box>
        </Box>
        
    );
}
