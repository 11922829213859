import React from "react";
import { useState, useEffect } from "react";

import { Container, Box, Grid, Typography, Button } from "@mui/material";

//
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import Select from '@mui/material/Select';
import {domain} from "../../../../config";
import { useAuth } from '../../../../contexts/AuthContext';

// Icon custom
import { Icon } from '@iconify/react';
import bxsAddToQueue from '@iconify/icons-bx/bxs-add-to-queue';
import { useSnackbar } from 'notistack';

// Components
import PreviewTable from "./PreviewTable"
import PrintShippingLabel from "./PrintShippingLabel"
export default function Shopee_scanner() {

  const {currentUser, logout} = useAuth();
  const paramUid = "?uid="+(currentUser.uid);

  const [filterData, setFilterData] = useState({
    limit: 20,
    page: 1,
    shop: "All",
    status: 0,
    sortBy: "created_newest",
    carrier:"All",
    searchField: "GTX_name",
    searchText: "",
    company:"centz"
    // create_date: [NaN, NaN]
  });

  const [stateSwitch, setStateSwitch] = React.useState(false);
  const [checkNote, setCheckNote] = React.useState(false);

  const handleChangeSwitch = (event) => {
    setStateSwitch(event.target.checked);
  };

  const handleCheckNote = (event) => {
    setCheckNote(event.target.checked);
  };

  const { enqueueSnackbar } = useSnackbar();

  const [loadedListCard, setLoadedListCard] = useState([]);


  const [dataPreview, setDataPreview] = React.useState([
  ]);

  const [openDetailView, setOpenDetailView] = React.useState(false);

  const getFirstItems = async (FirstFilterData) => {
    console.log(FirstFilterData);
    var data_domain = {
      "centz": "https://api.centz.vn",
      "sendragon": "https://api.sendragon.org",
      "hdecom": "https://api.hdecom.com",
    };
    setLoadedListCard([]);
    window[`scrollTo`]({top:0});
    await fetch(
      data_domain[filterData.company]+"/getScannerOrders"+paramUid,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify(FirstFilterData)
      }
    )
      .then(response => {
        return response.json();
      })
      .then(async data => {
        const sleep = (milliseconds) => {
          return new Promise(resolve => setTimeout(resolve, milliseconds))
      }
      
        setLoadedListCard(data["data"]);
        if(filterData.searchField == "send_carrier"){
          data["data"].forEach(order => {
            if(order["old_status"] > 4){
              var url_voice = "https://centz.vn/wrong_voice.mp3"
              var voice = new Audio(url_voice);
              voice.play();
              
              if(order["old_status"] == 9){
                enqueueSnackbar("Khách đã huỷ" ,{variant:'info'});
              }
              else if(order["old_status"] == 5){
                enqueueSnackbar("Đơn trùng" ,{variant:'info'});
              }else{
                enqueueSnackbar("ĐVVC đã nhận" ,{variant:'info'});
              }
            }else{
  
              if(order["check_carrier"]){
                var url_voice = "https://centz.vn/success_voice.mp3";
                var voice = new Audio(url_voice);
                voice.play();
                enqueueSnackbar("Cập nhật trạng thái thành công" ,{variant:'success'});
              }else{
                var url_voice = "https://centz.vn/wrong_voice.mp3"
                var voice = new Audio(url_voice);
                voice.play();
                enqueueSnackbar("Sai ĐVVC ===> "+ order["shipping_carrier"] ,{variant:'error'});
              }
              
              
            }
          });
        }else if(filterData.searchField == "GTX_name" && stateSwitch == true){
          
          await sleep(500);
          while(true){
            try {
              document.querySelector("#print_button").click();
              break;
              
            } catch (error) {
              await sleep(500);
            }
          }
          
        }
        
        if(filterData.searchField != "send_carrier" && checkNote == true){
          data["data"].forEach(async order => {

            if(order["note"] != ""){
              await sleep(1000);
              alert("Note: "+order["note"]);
            }
          });
        }

        
      });
  };
  ////////

  //shop
  const [shop, setShop] = React.useState("All");
  const handleChangeShop = (event) => {
    setShop(event.target.value);
  };

  //status
  const [status, setStatus] = React.useState(0);
  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  //sort
  const [sortBy, setSortBy] = React.useState('created_newest');
  const handleChangeSortBy = (event) => {
    setSortBy(event.target.value);
  };

  //print
  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };
  
  return (
    <Container maxWidth="xl" 
      sx={{minHeight: "90vh"}}
    >
      <Box  >
          <Grid container spacing={3} >
            <Grid item >
                <Typography variant="h4" sx={{fontWeight: 700}}>
                    Scanner
                </Typography>
            </Grid>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item >
                {/* <Button variant="contained" 
                    sx={{
                        fontWeight: 600,
                        padding: "8px 20px",
                        margin: "8px"
                    }}
                    startIcon={<Icon icon={bxsAddToQueue} />}

                    onClick={() => {setOpenDetailView(!openDetailView);console.log(openDetailView);}}
                >
                    Print
                </Button> */}
            </Grid>
          </Grid>

          <Box sx={{ alignItems: "center",display: "flex",flexWrap: "wrap"}}>
            <Box  sx={{flexGrow: 1,margin: "12px",marginLeft: "0px"}}>
              <FormControl fullWidth>
                <OutlinedInput
                  placeholder="Search Order"
                  fullWidth
                  startAdornment = {<InputAdornment position="start" size="medium" variant = "outlined"><SearchIcon /></InputAdornment>}
                  
                  value={filterData.searchText}
                  onKeyDown={(event) => {

                    if (event.key === 'Enter'){
                      filterData.searchText= event.target.value.trim().replaceAll("#","");
            
                      setFilterData({
                        ...filterData,
                        searchText: event.target.value.trim().replaceAll("#","")
                      });
                      console.log("Search... enter "+ event.target.value.trim().replaceAll("#",""));
            
                      getFirstItems(filterData);

                      setFilterData({
                        ...filterData,
                        searchText: ""
                      });
                    }
                  }}

                  onChange={(event) => {
                      filterData.searchText= event.target.value.trim().replaceAll("#","");
            
                      setFilterData({
                        ...filterData,
                        searchText: event.target.value.trim().replaceAll("#","")
                      });
                      console.log("Search... "+ event.target.value.trim().replaceAll("#",""));
          
                  }}

                  onClick={() => {
                    filterData.searchText= "";
          
                    setFilterData({
                      ...filterData,
                      searchText: ""
                    });
                  }}

                />
              </FormControl> 
            </Box>

            {/* company */}
            <FormControl sx={{marginLeft: "12px", "& .MuiOutlinedInput-root":{width: "250px"}}} >
              <InputLabel id="demo-simple-select-label">Company</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterData.company}
                label="Company"
                onChange={(event) => {
                  filterData.company= event.target.value;
                  filterData.page= 1;
        
                  setFilterData({
                    ...filterData,
                    company: event.target.value
                  });

                  setFilterData({
                    ...filterData,
                    page: 1
                  });
        
                  getFirstItems(filterData);
                }}

              >
                <MenuItem value={"centz"}>CentZ</MenuItem>
                <MenuItem value={"sendragon"}>Sendragon</MenuItem>
                <MenuItem value={"hdecom"}>HDecom</MenuItem>
              </Select>
            </FormControl>

            {/* Search by */}
            <FormControl sx={{marginLeft: "12px", "& .MuiOutlinedInput-root":{width: "250px"}}} >
              <InputLabel id="demo-simple-select-label">Search by</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filterData.searchField}
                label="Search by"
                onChange={(event) => {
                  filterData.searchField= event.target.value;
                  filterData.page= 1;
        
                  setFilterData({
                    ...filterData,
                    searchField: event.target.value
                  });

                  setFilterData({
                    ...filterData,
                    page: 1
                  });
        
                  getFirstItems(filterData);
                }}

              >
                <MenuItem value={"GTX_name"}>Quét đã nhận xưởng</MenuItem>
                <MenuItem value={"tracking_number"}>Kiểm tra bằng Tracking number</MenuItem>
                <MenuItem value={"send_carrier"}>Giao bưu cục</MenuItem>
              </Select>
            </FormControl>

            {/* Carrier */}
            { filterData.searchField !== "send_carrier" ? null :
              <FormControl sx={{marginLeft: "12px", "& .MuiOutlinedInput-root":{width: "170px"}}} >
                <InputLabel id="demo-simple-select-label">ĐVVC</InputLabel>
                <Select
                  value={filterData.carrier}
                  label="ĐVVC"
                  onChange={(event) => {
                    filterData.carrier= event.target.value;
                    filterData.page= 1;
          
                    setFilterData({
                      ...filterData,
                      carrier: event.target.value
                    });

                    setFilterData({
                      ...filterData,
                      page: 1
                    });
          
                    getFirstItems(filterData);
                  }}

                >
                  <MenuItem value={"All"}>All</MenuItem>
                  <MenuItem value={"Shopee Express"}>Shopee Express</MenuItem>
                  <MenuItem value={"J&T Express"}>J&T Express</MenuItem>
                  <MenuItem value={"BEST Express"}>BEST Express</MenuItem>
                  <MenuItem value={"Giao Hàng Nhanh"}>Giao Hàng Nhanh</MenuItem>
                  <MenuItem value={"Giao Hàng Tiết Kiệm"}>Giao Hàng Tiết Kiệm</MenuItem>
                  <MenuItem value={"Viettel Post"}>Viettel Post</MenuItem>
                  <MenuItem value={"VNPost Nhanh"}>VNPost Nhanh</MenuItem>
                  <MenuItem value={"Ninja Van"}>Ninja Van</MenuItem>
                </Select>
              </FormControl>

              
            }
            <FormControl sx={{ml:2}}>
              <FormControlLabel
                control={
                  <Switch checked={stateSwitch} onChange={handleChangeSwitch} name="Auto Print" />
                }
                label="Auto Print"
              />
            </FormControl>

            <FormControl sx={{ml:2}}>
              <FormControlLabel
                control={
                  <Switch checked={checkNote} onChange={handleCheckNote} name="Check Note" />
                }
                label="Check Note"
              />
            </FormControl>

          </Box>

          <PreviewTable loadedListCard={loadedListCard} />

          {/* {loadedListCard.map((row) => (
            <PrintShippingLabel shipping_label={row.shipping_label} order_sn={row.order_sn} />
          ))}
           */}
      </Box>
    </Container>
  );
}
