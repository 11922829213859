import React from "react";
import { useState, useEffect } from "react";

import { Container, Box, Grid, Typography, Button, Card, CardContent } from "@mui/material";

import QrReader from 'react-qr-reader';
//
import FormControl, { useFormControl } from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {domain} from "../../../../config";
import { useAuth } from '../../../../contexts/AuthContext';

// Icon custom
import { Icon } from '@iconify/react';
import bxsAddToQueue from '@iconify/icons-bx/bxs-add-to-queue';

// Components
import PreviewTable from "./PreviewTable"

export default function Shopee_scanner() {
    
  const {currentUser, logout} = useAuth();
  const paramUid = "?uid="+(currentUser.uid);

  const [scanResult, setScanResult] = React.useState("None");
  const [scanSave, setScanSave] = React.useState("None");
  const [orderSn, setOrderSn] = React.useState("None");
  const [orderStatus, setOrderStatus] = React.useState("None");

  const [count_mica, setCount_mica] = React.useState(0);

  

  const [camera, setCamera] = React.useState(false);
  
  const [loadedListCard, setLoadedListCard] = useState([]);

  const [filterData, setFilterData] = useState({
    searchField: "tracking_number",
    searchText: ""
  });

  const handleErrorWebCam = (error) => {
    console.log(error);
  }

  const getFirstItems = async (filterData,result) => {
    if(filterData.searchText != "None"){
      setLoadedListCard([]);
      setCount_mica(0);
      setCamera(false);
      window[`scrollTo`]({top:0});

      if(result != ""){
        await fetch(
          domain+"/getOrderByTrackingNumber"+paramUid,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify({
              "searchField": filterData.searchField,
              "searchText": result,

            })
          }
        )
          .then(response => {
            console.log(response.ok);
            if(response.ok)
            {
              return response.json();  
            }else{
              console.log("Error");
              return;
            }
            
          })
          .then(data => {
            setLoadedListCard(data["data"]);
            setCount_mica(data["count_mica"]);
            setOrderSn(data["order_sn"]);

            setOrderStatus(data["order_status"]);
          });
      }
    }
    
  };

  const handleScanWebCam = async (result) => {
    if(result){
      // if(scanSave != result){
        console.log("result", result);
        setOrderSn("Waiting...");
        setOrderStatus("Waiting...");

        setScanResult(result);
        setScanSave(result);

        setScanSave(result);

        setFilterData({
          ...filterData,
          searchText: result
        });

        setLoadedListCard([]);
        setCount_mica(0);
        
        await new Promise(resolve => setTimeout(resolve, 500));

        
        getFirstItems(filterData,result);

      // }
      
    }else{
      // setScanResult("");

    }
  }

  
  return (
    <Container maxWidth="xl" 
      sx={{minHeight: "90vh"}}
    >
      <Box  >
          {/* <Grid container spacing={3} >
            <Grid item >
                <Typography variant="h4" sx={{fontWeight: 700}}>
                    Scanner
                </Typography>
            </Grid>
            <Box sx={{ flexGrow: 1 }} />
            <Grid item >
               
            </Grid>
          </Grid> */}

          <Box sx={{ alignItems: "center",display: "flex",flexWrap: "wrap"}}>
            <Box  sx={{flexGrow: 1,margin: "12px",marginLeft: "0px"}}>
            
              <Grid container spacing={2}>
                <Grid item xs={8} md={8}>
                <FormControl fullWidth sx={{marginLeft: "12px"}} >
                    <InputLabel id="demo-simple-select-label">Search By</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filterData.searchField}
                      label="Search By"
                      onChange={(event) => {
                        filterData.searchField= event.target.value;
              
                        setFilterData({
                          ...filterData,
                          searchField: event.target.value
                        });

                        // getFirstItems(filterData);
                      }}
                    >
                      <MenuItem value={"tracking_number"}>Tracking Number</MenuItem>
                      <MenuItem value={"item_list.GTX_name"}>GTX Name</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Button 
                  fullWidth
                    variant="contained" 
                    onClick={() => {
                      setCamera(true);
                    }}
                  >Scan</Button>
                  
                </Grid>
                
              </Grid>
            </Box>
          </Box>

          <Container >
              <Card>
                {/* <h2> Scanner QR </h2> */}

                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          {/* <h3>QR code Scan by Web Cam</h3> */}
                          { camera?
                          <QrReader 
                            delay={1000}
                            style={{
                              width: '100%', 
                              // transform: 'rotateY(180deg)', 
                              // transition: 'transform 150ms ease'
                            }}
                            onError={handleErrorWebCam}
                            onScan={handleScanWebCam}

                          />
                          : null}

                          
                          <h3>
                            {filterData.searchField == "tracking_number"? "Tracking Number: ": "GTX Name: "}
                            
                            
                          {scanResult}</h3>

                          <h3>Order ID: {orderSn}</h3>
                          <h3>Count Mica: {count_mica}</h3>
                          <h3>Count Items: {loadedListCard.length}</h3>
                          <h3>Status: {orderStatus}</h3>
                          
                        </Grid>

                        {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          b
                        </Grid>

                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          c
                        </Grid> */}

                    </Grid>

                </CardContent>
              </Card>

          </Container>
          
          <PreviewTable loadedListCard={loadedListCard}/>

      </Box>
    </Container>
  );
}
