import React from "react";
import AuthProvider from "../contexts/AuthContext";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Login from "./Login/Login";

import Signup from "./Login/Signup";
import ForgotPassword from "./Login/ForgotPassword";
import UpdateProfile from "./Login/UpdateProfile";
import Dashboard from "./Dashboard";
import PrivateRoute from "./PrivateRoute";

function App() {
  return (
    <div>
      <Router>
        <AuthProvider>
          <Switch>
            <PrivateRoute exact path="/update-profile" component={UpdateProfile} />
            <Route path= "/signup" component={Signup} />
            <Route path= "/login" component={Login} />
            <Route path= "/forgot-password" component={ForgotPassword} />

            <PrivateRoute path="/" component={Dashboard} />

          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
