import React, { useState } from "react";
import {
  Grid,
  Button,
  TextField,
  Alert,
  Box,
  Typography,
  Paper,
  Divider,
  Link
} from "@mui/material";
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { useAuth } from "../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import {domain} from "../../config";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
//   height: 60,
//   lineHeight: "60px",
  borderRadius: "8px",
  padding: "32px"
}));



export default function Login() {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [passwordConfirm,setPasswordConfirm] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const {currentUser, signup} = useAuth();
    // currentUser.email

    const history = useHistory();

    async function handleSubmit(e) {
        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }

        e.preventDefault();

        if (password !== passwordConfirm){
            return setError("Passwords do not match");
        }
        
        try {
            setError("");
            setLoading(true);
            await signup(email, password);
            console.log("apiCreateMember()");
            await sleep(2000);
            apiCreateMember();
            console.log("done apiCreateMember()");
            history.push("/")
        } catch {
            setError("Fail to create an account");
        }
        setLoading(false);
    }

    const apiCreateMember = async () => {
        const sleep = (milliseconds) => {
            return new Promise(resolve => setTimeout(resolve, milliseconds))
        }
    
        
        await fetch(
          domain+"/createMember",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify({"email": email,"uid": currentUser.uid})
          }
        )
          .then(response => {
            return response.json();
          })
          .then(data => {
            console.table(data);
    
            // if(data["status"] == "successful"){
            //   enqueueSnackbar("Cập nhật trạng thái thành công" ,{variant:'success'});
            // }else{
            //   enqueueSnackbar("Không thể Cập nhật trạng thái" ,{variant:'error'});
            // }
            
            // console.table("Done");
            
          });
          await sleep(500);
      };

    return (
    <div>
        <Grid container sx={{ minHeight: "100vh" }}>
        <Grid
            container
            item
            xs={12}
            sm={12}
            alignItems="center"
            direction="column"
            justifyContent="center"
            sx={{ p: 10 }}
            
        >
            <div>{error && <Alert severity="error" sx={{ mb: 5 }}>{error}</Alert>}</div>

            <div />

            <Box sx={{ width: "600px", "& *": {borderRadius: "8px"} }}>
            <Item key="16" elevation="16">
                {/* Header */}
                <div style={{ height: 7 }} />
                <Box mt={2}>
                    <Typography variant="h4" component="h4" sx={{color:"black",fontWeight: 700}}>
                        Sign Up
                    </Typography>
                    
                    <div style={{ height: 20 }} />

                    <Typography variant="body2" component="p">
                        Create new account to join the internal platform
                    </Typography>
                    
                    <div style={{ height: 20 }} />
                </Box>

                <Box mt={2} >
                    
                <TextField
                    required
                    id="email"
                    onChange={(event)=> setEmail(event.target.value)}
                    label="Email Address"
                    variant="outlined"
                    color="primary" 
                    sx={{ mb: 4 }}
                    fullWidth
                />

                <TextField
                    required
                    type="password"
                    id="password"
                    onChange={(event)=> setPassword(event.target.value)}
                    label="Password"
                    variant="outlined"
                    sx={{ mb: 4 }}
                    fullWidth
                />

                <TextField
                    required
                    type="password"
                    id="password-confirm"
                    onChange={(event)=> setPasswordConfirm(event.target.value)}
                    label="Password Confirmation"
                    variant="outlined"
                    sx={{ mb: 4 }}
                    fullWidth
                />
                
                <Button 
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={loading}
                    sx={{padding: "11px 24px", fontWeight: 600}}
                    fullWidth
                >
                    Register
                </Button>
                
                <Divider sx={{margin: "24px 0px"}}/>

                <Grid container >
                    <Grid
                        container
                        item
                        xs={6}
                        sm={6}
                        alignItems="flex-start"
                        direction="column"
                        justifyContent="flex-start"
                    >   
                        <Link component={RouterLink} to='/login' underline="none">
                            Already have an account?
                        </Link>
                    </Grid>

                </Grid>
                

                </Box>

            </Item>
            </Box>
        </Grid>
        </Grid>
        </div>
    );
}
