import React from "react";
import ReactToPrint from "react-to-print";

import {Grid, Button} from '@mui/material';

import {domain} from "../../../../config";
import printJS from 'print-js';

class ComponentToPrint extends React.Component {
  render(props) {
    return (
      <img
        src={this.props.shipping_label}
        alt="Logo"
        style={{
            "marginLeft": "auto",
            "marginRight": "auto",
        }}
      />
    );
  }
}

class ComponentToPrintInventoryVoucher extends React.Component {
  render(props) {
    return (
      <img
        src={this.props.inventory_voucher}
        alt="Logo"
        style={{
            "marginLeft": "auto",
            "marginRight": "auto",
        }}
      />
    );
  }
}



class PrintShippingLabel extends React.Component {
  render() {
    const updateOrderStatus = async () => {
        console.log("updateOrderStatus");
        await fetch(
          domain+"/updateOrderStatus",
          {
            method: "POST",
            headers: {
              Accept: "application/json",
            },
            body: JSON.stringify({"order_sn": this.props.order_sn, "status": 4})
          }
        )
          .then(response => {
            return response.json();
          })
          .then(data => {
            document.querySelector("#inventory_voucher").click();
            console.table("Done");
          });
      };

      

      

    return (
      <div>
        <Grid container spacing={3} sx={{marginTop: 0}} >
            <Grid item xs={6} md={6}>
                {/* <ReactToPrint 
                  onAfterPrint={updateOrderStatus} 
                  trigger={() => <Button variant="outlined" fullWidth href="#">In Phiếu giao</Button>}
                  content={() => this.componentRef}
                /> */}
                <Button 
                    variant="outlined" 
                    fullWidth href="#"
                    onClick={async (event) => {
                      console.log("click printJS"); 
                      // printJS(domain+'/downloadFile?url=https://cdn.discordapp.com/attachments/868480690738589706/974278099212570654/576514172059093911.pdf')
                      printJS('https://api.codetabs.com/v1/proxy/?quest=https://cdn.discordapp.com/attachments/868480690738589706/974278099212570654/576514172059093911.pdf')
                    }}
                    
                  >In Phiếu giao2</Button>}
            </Grid>
            <Grid item xs={6} md={6}>
            <Button variant="outlined" fullWidth href={this.props.inventory_voucher} id="inventory_voucher">In Phiếu xuất kho</Button>
            </Grid>

            {/* <Grid item xs={6} md={6}>
                <ReactToPrint 
                  trigger={() => <Button variant="outlined" fullWidth href="#" id="inventory_voucher">In Phiếu xuất kho</Button>}
                  content={() => this.componentRefInventoryVoucher}
                />
            </Grid> */}

            
        </Grid>
        
        <ComponentToPrint
            ref={el => (this.componentRef = el)}
            shipping_label={this.props.shipping_label}
            sx={{"marginLeft": "auto","marginRight": "auto"}}
        />
        <ComponentToPrintInventoryVoucher
            ref={el => (this.componentRefInventoryVoucher = el)}
            inventory_voucher={this.props.inventory_voucher}
            sx={{"marginLeft": "auto","marginRight": "auto"}}
        />
      </div>
    );
  }
}

export default PrintShippingLabel;
