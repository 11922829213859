import React from "react";
import { useState, useEffect } from "react";

import { Container, Box, Grid, Typography, Button ,Paper, FormControl, MenuItem,InputLabel,Select,Toolbar} from "@mui/material";

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

// Icon custom
import { Icon } from '@iconify/react';
import bxsAddToQueue from '@iconify/icons-bx/bxs-add-to-queue';
// Components
import PreviewTable from "./PreviewTable"
import {domain} from "../../../../config";
import { useAuth } from '../../../../contexts/AuthContext';

import NumberFormat from 'react-number-format';
import MyApexChart from "./MyApexChart";

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import TextField from '@mui/material/TextField';
import { LicenseInfo } from '@mui/x-date-pickers-pro';

LicenseInfo.setLicenseKey(
  'x0jTPl0USVkVZV0SsMjM1kDNyADM5cjM2ETPZJVSQhVRsIDN0YTM6IVREJ1T0b9586ef25c9853decfa7709eee27a1e',
);


export default function Etsy_import_products() {

  const [value, setValue] = React.useState([null, null]);
  ////////////
  const [dataPreview, setDataPreview] = React.useState([
  ]);

  const [openDetailView, setOpenDetailView] = React.useState(false);

  const [loadedListCard, setLoadedListCard] = useState([]);

  const [stateData,setStateData] = useState({
    series: [
        {
            name: 'Orders2',
            data: []
        }
    ],
    options: {
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          download: {show: false}
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      xaxis: {
        type: 'datetime',
        categories: []
      },
      tooltip: {
        x: {
          format: 'HH:mm:ss dd/MM/yyyy'
        },
      },
    },
  });

  
  const [totalSales, setTotalSales] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);

  const {currentUser, logout} = useAuth();
  const paramUid = "?uid="+(currentUser.uid);
  const [listShop, setListShop] = useState([]);
  
  // getDashboard

  const getItems = async () => {
    setLoadedListCard([]);
    var date = new Date();
    var data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();

    const filterDataDefault = {
      shop: "All",
      product_type: "All",
      date: "Today",
      from:data_from,
      to:"",
      filterField:"create_time",
      groupType: "duration" // ["date","duration"]
    }
    // setIsLoading(true);
    await fetch(
      domain+"/getDashboard"+paramUid,
      {
        method: "POST",
        headers:{
          "Accept": "application/json",
        },
        body: JSON.stringify(filterDataDefault)
      }
    )
      .then(response => {
        return response.json();
      })
      .then(async data => {
        const sleep = (milliseconds) => {
          return new Promise(resolve => setTimeout(resolve, milliseconds))
        }

        // setIsLoading(false);
        setLoadedListCard(data["data"]);

        setTotalSales(data["total"]["total_sales"]);
        setTotalOrders(data["total"]["total_orders"]);
        setTotalRevenue(data["total"]["total_revenue"]);

        setStateData({
          ...stateData,
          series: [ { name: 'Orders', data: data["chartData"]["listValue"] } ],
          options: {chart: { height: 350, type: 'area', toolbar: { download: {show: false} } }, dataLabels: { enabled: false }, stroke: { curve: 'smooth' }, xaxis: { type: 'datetime', categories: data["chartData"]["listName"],labels: { datetimeUTC: false } }, tooltip: { x: {  format: filterData.groupType == "duration"?'HH:mm dd/MM/yyyy':'dd/MM/yyyy'  } }}
        });

        console.log(stateData);
      });
  };

  const getShops = async () => {
    await fetch(
      domain+"/getShop",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({})
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        setListShop(data["list_shop"]);
      });
  };

  useEffect(() => {
    getItems();
    getShops();

  }, []);

  const getFirstItems = async (FirstFilterData) => {
    setLoadedListCard([]);

    console.log(FirstFilterData);
    window[`scrollTo`]({top:0});
    // setNoMore(true);
    // setIsLoading(true);
    await fetch(
      domain+"/getDashboard"+paramUid,
      {
        method: "POST",
        headers: {
          "Accept": "application/json",
        },
        body: JSON.stringify(FirstFilterData)
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        // setIsLoading(false);
        setLoadedListCard(data["data"]);
        
        setTotalSales(data["total"]["total_sales"]);
        setTotalOrders(data["total"]["total_orders"]);
        setTotalRevenue(data["total"]["total_revenue"]);

        setStateData({
          ...stateData,
          series: [ { name: 'Orders', data: data["chartData"]["listValue"] } ],
          options: {chart: { height: 350, type: 'area', toolbar: { download: {show: false} } }, dataLabels: { enabled: false }, stroke: { curve: 'smooth' }, xaxis: { type: 'datetime', categories: data["chartData"]["listName"],labels: { datetimeUTC: false } }, tooltip: { x: {  format: filterData.groupType == "duration"?'HH:mm dd/MM/yyyy':'dd/MM/yyyy' } }}
        });
        console.log(stateData);
      });
  };
  //////
  
  const [filterData, setFilterData] = useState({
    shop: "All",
    product_type: "All",
    date: "Today",
    from:"",
    to:"",
    filterField:"create_time",
    groupType: "duration" // ["date","duration"],
  });

  const [alignment, setAlignment] = React.useState('web');

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  
  return (
    <Container maxWidth="xl" 
      sx={{minHeight: "90vh"}}
    >
      <Box  >
          <Grid container spacing={3} sx={{marginBottom: "1%"}} >
            <Grid item >
                <Typography variant="h4" sx={{fontWeight: 700}}>
                Dashboard
                </Typography>
            </Grid>
            <Box sx={{ flexGrow: 1 }} />

            <Grid item lg={8} sx={{ display: { xs: 'none', sm: 'block',md: 'block',lg: 'block' } , pt: "35px!important"}}>
              <ToggleButtonGroup fullWidth
                color="primary"
                value={filterData.date}
                exclusive
                onChange={(event) => {
                  console.log("date: "+event.target.value)
                  var data_from = "";
                  var data_to = "";

                  var date = new Date();
                  
                  if( event.target.value == "Today" || event.target.value == "Yesterday"){
                    filterData.groupType= "duration";
                    setFilterData({
                      ...filterData,
                      groupType: "duration"
                    });
                  }else{
                    filterData.groupType= "date";
                    setFilterData({
                      ...filterData,
                      groupType: "date"
                    });
                  }

                  switch(event.target.value) {
                    case "Today":
                      data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                      data_to = "";
                      break;
                    case "Yesterday":
                      data_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                      date.setDate(date.getDate() - 1);
                      data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                      break;
                    case "Last 7 days":
                      date.setDate(date.getDate() - 7);
                      data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                      data_to = "";
                      break;
                    case "This week":
                      date.setDate(date.getDate() - (date.getDay() == 0 ? 6:date.getDay()-1));
                      data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                      data_to = "";
                      break;
                    case "Last month":
                        date.setDate(date.getDate() - 30);
                        data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                        data_to = "";
                        break;
                    case "This month":
                        data_from = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0).getTime();
                        data_to = "";
                        // code block
                        break;
                    case "All time":
                        data_from = "";
                        data_to = "";
                      // code block
                      break;
                    default:
                      // code block
                  }

                  filterData.date= event.target.value;
                  filterData.from= data_from;
                  filterData.to= data_to;
        
                  setFilterData({
                    ...filterData,
                    date: event.target.value
                  });

                  setFilterData({
                    ...filterData,
                    from: data_from
                  });

                  setFilterData({
                    ...filterData,
                    to: data_to
                  });

                  console.log(filterData);

                  getFirstItems(filterData);
                }}
                sx={{
                  "& .MuiButtonBase-root": {color:"#2f3237;"},
                  "& .Mui-selected": {color:"rgb(25 118 210 / 42%)"},
                  height: "30px"
                }}
              >
                <ToggleButton value="Today">Today</ToggleButton>
                <ToggleButton value="Yesterday">Yesterday</ToggleButton>
                <ToggleButton value="Last 7 days">Last 7 days</ToggleButton>
                {/* <ToggleButton value="This week">This week</ToggleButton> */}
                <ToggleButton value="Last month">Last month</ToggleButton>
                <ToggleButton value="This month">This month</ToggleButton>
                <ToggleButton value="All time">All time</ToggleButton>
                {/* <ToggleButton value="Custom">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      localeText={{ start: 'Check-in', end: 'Check-out' }}
                    >
                      <DateRangePicker
                        value={value}
                        onChange={(newValue) => {
                          setValue(newValue);
                        }}
                        renderInput={(startProps, endProps) => (
                          <React.Fragment>
                            <TextField {...startProps} />
                            <Box sx={{ mx: 2 }}> to </Box>
                            <TextField {...endProps} />
                          </React.Fragment>
                        )}
                      />
                    </LocalizationProvider>
                </ToggleButton> */}
              </ToggleButtonGroup>
            </Grid>

            {/* <Grid item >
                
            </Grid> */}
          </Grid>

          <Grid container xs={12} spacing={3} sx={{marginBottom: "1%"}} >
            <Grid item xs={12} md={6} lg={2}>
              <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Shop</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.shop}
                    label="Shop"

                    onChange={(event) => {
                      filterData.shop= event.target.value;
            
                      setFilterData({
                        ...filterData,
                        shop: event.target.value
                      });

                      getFirstItems(filterData);
                    }}

                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    {listShop.map((shop_item) => (
                      <MenuItem value={shop_item}>{shop_item}</MenuItem>
                    ))}
                  </Select>
              </FormControl>

            </Grid>
            <Grid item xs={12} md={6} lg={2}>
              <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Product Type</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.product_type}
                    label="Product Type"

                    onChange={(event) => {
                      filterData.product_type= event.target.value;
            
                      setFilterData({
                        ...filterData,
                        product_type: event.target.value
                      });

                      getFirstItems(filterData);
                    }}

                  >
                    <MenuItem value={"All"}>All</MenuItem>
                    <MenuItem value={"Standee"}>Standee</MenuItem>
                    <MenuItem value={"Tshirt"}>Tshirt</MenuItem>
                    <MenuItem value={"Hoodie"}>Hoodie</MenuItem>
                    <MenuItem value={"Phụ kiện"}>Móc khoá</MenuItem>
                    <MenuItem value={"Led"}>Đèn led</MenuItem>
                    <MenuItem value={"Poster"}>Tranh dán tường</MenuItem>
                  </Select>
              </FormControl>
            
            </Grid>

            {/* Select Field */}
            <Grid item xs={12} md={6} lg={2}>
              <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Select Field</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.filterField}
                    label="Select Field"

                    onChange={(event) => {
                      filterData.filterField= event.target.value;
            
                      setFilterData({
                        ...filterData,
                        filterField: event.target.value
                      });

                      getFirstItems(filterData);
                    }}

                  >
                    <MenuItem value={"create_time"}>Create Time</MenuItem>
                    <MenuItem value={"shiper_taken"}>ĐVVC nhận</MenuItem>
                  </Select>
              </FormControl>
            
            </Grid>

            <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none',md: 'none',lg: 'none' } }}>
              <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Time</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filterData.date}
                    label="Time"

                    onChange={(event) => {
                      console.log("date: "+event.target.value)
                      var data_from = "";
                      var data_to = "";
    
                      var date = new Date();
                      
                      if( event.target.value == "Today" || event.target.value == "Yesterday"){
                        filterData.groupType= "duration";
                        setFilterData({
                          ...filterData,
                          groupType: "duration"
                        });
                      }else{
                        filterData.groupType= "date";
                        setFilterData({
                          ...filterData,
                          groupType: "date"
                        });
                      }
    
                      switch(event.target.value) {
                        case "Today":
                          data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                          data_to = "";
                          break;
                        case "Yesterday":
                          data_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                          date.setDate(date.getDate() - 1);
                          data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                          break;
                        case "Last 7 days":
                          date.setDate(date.getDate() - 7);
                          data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                          data_to = "";
                          break;
                        case "This week":
                          date.setDate(date.getDate() - (date.getDay() == 0 ? 6:date.getDay()-1));
                          data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                          data_to = "";
                          break;
                        case "Last month":
                            date.setDate(date.getDate() - 30);
                            data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                            data_to = "";
                            break;
                        case "This month":
                            data_from = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0).getTime();
                            data_to = "";
                            // code block
                            break;
                        case "All time":
                            data_from = "";
                            data_to = "";
                          // code block
                          break;
                        default:
                          // code block
                      }
    
                      filterData.date= event.target.value;
                      filterData.from= data_from;
                      filterData.to= data_to;
            
                      setFilterData({
                        ...filterData,
                        date: event.target.value
                      });
    
                      setFilterData({
                        ...filterData,
                        from: data_from
                      });
    
                      setFilterData({
                        ...filterData,
                        to: data_to
                      });
    
                      console.log(filterData);
    
                      getFirstItems(filterData);
                    }}

                  >
                    
                    <MenuItem value="Today">Today</MenuItem>
                    <MenuItem value="Yesterday">Yesterday</MenuItem>
                    <MenuItem value="Last 7 days">Last 7 days</MenuItem>
                    {/* <MenuItem value="This week">This week</MenuItem> */}
                    <MenuItem value="Last month">Last month</MenuItem>
                    <MenuItem value="This month">This month</MenuItem>
                    <MenuItem value="All time">All time</MenuItem>
                  </Select>
              </FormControl>
            
            </Grid>
            

            
        </Grid>

          {/* <Toolbar>
            
              <Box sx={{ flexGrow: 1 }} />
            

            <ToggleButtonGroup
              color="primary"
              value={filterData.date}
              exclusive
              onChange={(event) => {
                console.log("date: "+event.target.value)
                var data_from = "";
                var data_to = "";

                var date = new Date();


                switch(event.target.value) {
                  case "Today":
                    data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                    data_to = "";
                    break;
                  case "Yesterday":
                    data_to = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                    date.setDate(date.getDate() - 1);
                    data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                    break;
                  case "Last 7 days":
                    date.setDate(date.getDate() - 7);
                    data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                    data_to = "";
                    break;
                  case "This week":
                    date.setDate(date.getDate() - (date.getDay() == 0 ? 6:date.getDay()-1));
                    data_from = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0).getTime();
                    data_to = "";
                    break;
                  case "This month":
                      data_from = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0).getTime();
                      data_to = "";
                      // code block
                      break;
                  case "All time":
                      data_from = "";
                      data_to = "";
                    // code block
                    break;
                  default:
                    // code block
                }

                filterData.date= event.target.value;
                filterData.from= data_from;
                filterData.to= data_to;
      
                setFilterData({
                  ...filterData,
                  date: event.target.value
                });

                setFilterData({
                  ...filterData,
                  from: data_from
                });

                setFilterData({
                  ...filterData,
                  to: data_to
                });

                console.log(filterData);

                getFirstItems(filterData);
              }}
              sx={{
                "& .MuiButtonBase-root": {color:"#2f3237;"},
                "& .Mui-selected": {color:"rgb(25 118 210 / 42%)"}
              }}
            >
              <ToggleButton value="Today">Today</ToggleButton>
              <ToggleButton value="Yesterday">Yesterday</ToggleButton>
              <ToggleButton value="Last 7 days">Last 7 days</ToggleButton>
              <ToggleButton value="This week">This week</ToggleButton>
              <ToggleButton value="This month">This month</ToggleButton>
              <ToggleButton value="All time">All time</ToggleButton>
            </ToggleButtonGroup>
          </Toolbar> */}

          
          <br></br>

          <Grid container xs={12} spacing={2} sx={{marginLeft: 0}} >
            <Grid item xs={12} md={6} lg={4} sx={{paddingTop:"32px", paddingLeft: {xs:"0!important"}}}>
              <Paper elevation={1} sx={{padding: "24px"}}>
                <Box >
                  <Typography variant="h5" component="h5" ><NumberFormat value={totalRevenue} displayType={'text'} thousandSeparator={true} /> đ</Typography>
                </Box>
                <Typography variant="body2" component="p" >Revenue</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{paddingTop:"32px", paddingLeft: {xs:"0!important"}}}>
              <Paper elevation={1} sx={{padding: "24px"}}>
                <Box >
                  <Typography variant="h5" component="h5" >{totalOrders}</Typography>
                </Box>
                <Typography variant="body2" component="p" >
                  Orders
                </Typography>

              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sx={{paddingTop:"32px", paddingLeft: {xs:"0!important"}}}>
              <Paper elevation={1} sx={{padding: "24px"}}>
                <Box >
                  <Typography variant="h5" component="h5" >{totalSales}</Typography>
                </Box>
                <Typography variant="body2" component="p" >
                  Sales
                </Typography>

              </Paper>
            </Grid>

            <Grid item xs={12} md={12} lg={12} sx={{paddingTop:"32px", paddingLeft: {xs:"0!important"}}}>
              <MyApexChart 
                // nameData={nameData} valueData={valueData} 
                filterData={filterData} stateData={stateData}/>
            </Grid>
          </Grid>
          
          <PreviewTable loadedListCard={loadedListCard} fullWidth/>
          {/* <PreviewTable dataPreview={dataPreview} openDetailView={openDetailView} setOpenDetailView={setOpenDetailView}/> */}

      </Box>
    </Container>
  );
}
