import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';

import {
  Link as RouterLink
} from 'react-router-dom';
import Link from "@mui/material/Link";

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { disable } from 'workbox-navigation-preload';
// import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import {domain} from "../../../../config";
import { useAuth } from '../../../../contexts/AuthContext';
import printJS from 'print-js';
import { useSnackbar } from 'notistack';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Row(props) {
  const { enqueueSnackbar } = useSnackbar();

  const {currentUser, logout} = useAuth();
  const paramUid = "?uid="+(currentUser.uid);
  const [statusButtonCreateGHN, setStatusButtonCreateGHN] = React.useState(true);

  const { row } = props;

  const updateOrderStatus = async () => {
    await fetch(
      domain+"/updateOrderStatus"+paramUid,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({"order_sn": row.order_sn, "status": 4})
      }
    )
      .then(response => {
        return response.json();
      })
      .then(data => {
        console.table("Done");
      });
  };

  const printIframe = (id) => {
    const iframe = document.frames
      ? document.frames[id]
      : document.getElementById(id);
    const iframeWindow = iframe.contentWindow || iframe;

    iframe.focus();
    iframeWindow.print();

    return false;
  };

  const createShipingLabelGHN = async () => {
    console.log("createShipingLabelGHN");
    enqueueSnackbar("Đang tạo phiếu giao" ,{variant:'warning'});
    setStatusButtonCreateGHN(false);


    try {
      await fetch(
        domain+"/createShipingLabelGHN"+paramUid,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: JSON.stringify({"order_sn": row.order_sn})
        }
      )
        .then(response => {
          return response.json();
        })
        .then(data => {
            if(data["status"] == "successful"){
              enqueueSnackbar("Cập nhật trạng thái thành công" ,{variant:'success'});
              printJS(domain+'/downloadFile?url='+data["shipping_label"]);
              
            }else{
              if( "message" in data){
                enqueueSnackbar(data["message"] ,{variant:'error'});
              }else{
                enqueueSnackbar("Không thể Cập nhật trạng thái" ,{variant:'error'});
              }
            }
          
          console.table("Done");
        });
      } catch (error) {
        enqueueSnackbar("Không thể Cập nhật trạng thái" ,{variant:'error'});
      }
      setStatusButtonCreateGHN(true);
  };



  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell style={{ maxWidth: 33 }}>
          <IconButton
            aria-label="expand row"
            size="small"
          >
            <Checkbox {...label} />
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row"  align="center">
          {/* #{row.order_sn} */}
          <Link component={RouterLink} to={`/shopee/orders/${row._id}`} underline="none" >
            #{row.order_sn}
          </Link>
        </TableCell>
        <TableCell align="center">{row.shop}</TableCell>
        <TableCell align="center">{row.status_vn} <Chip label={row.status_chip} size="medium"/></TableCell>
        <TableCell align="center">{new Date(row.create_time*1000).toLocaleDateString("en-GB")}</TableCell>
        <TableCell align="center">{row.shop == "Pancake" || row.ship_by_date == 0? "" : new Date(row.ship_by_date*1000).toLocaleDateString("en-GB")}</TableCell>

        <TableCell align="center">
          {/* <Button 
              variant="outlined" 
              fullWidth 
              sx={{marginTop: "2%", marginBottom: "5%"}}
              id="print_button"
              onClick={async (event) => {
                console.log("click printJS");
                printJS(domain+'/downloadFile?url='+row.shipping_label)
              }}
            >In Phiếu giao
          </Button> */}
              {"shipping_label" in row ? 
                  <Button 
                    variant="outlined" 
                    fullWidth
                    id="print_button"
                    sx={{marginTop: "2%", marginBottom: "5%"}}
                    onClick={async (event) => {
                      console.log("click printJS");
                      printJS(domain+'/downloadFile?url='+row.shipping_label)
                    }}
                  >In Phiếu giao</Button>
                  : null
                }
                {row.source == "Salework" && row.note_price_and_addess_status > 0 && !("shipping_label" in row)? 
                  <Button 
                    variant="outlined" 
                    onClick={createShipingLabelGHN} 
                    id="print_button"
                    disabled = {!statusButtonCreateGHN}
                    fullWidth 

                    sx={{marginTop: "2%", marginBottom: "5%"}}
                    
                  >Tạo Phiếu giao GHN</Button>
                  : null
                }

        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={true} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                {/* more */}
                <TableBody>
                  {row.item_list.map((item) => (
                    <TableRow key={item.date}>
                      <TableCell>
                        <Box sx={{alignItems: 'center',display: 'flex'}}>
                          <Box 
                            sx={{
                              width: 500,
                              height: 500,
                              backgroundColor: 'rgb(249, 250, 252);',
                              backgroundImage: `url(${[ "Phone Case Custom"].includes(item.print_type)?item.artwork_front:item.image_info.image_url})`,
                              backgroundSize: 'contain',
                              backgroundRepeat: 'no-repeat',
                              backgroundPosition: 'center',

                              // maxHeight: '160px',
                              borderRadius: '8px',
                              display: 'flex',
                              justifyContent: 'center',
                              overflow: 'hidden'
                            }}
                          >

                          </Box>
                          <Box
                            sx={{
                              cursor: 'pointer',
                              marginLeft: 8,
                              "& p": {
                                paddingTop: 1
                              },
                              "& svg": {
                                marginBottom: "-0.7%"
                              }
                            }}
                          >
                            
                            <Typography variant="subtitle2" component="h8">
                              {item.taken_factory_text} <Chip label={item.taken_factory_chip} size="medium"/>

                            </Typography>
                            {/* Title */}
                            <Typography variant="subtitle2" component="h6">
                              Title: {item.item_name} 
                            </Typography>

                            {/* Variant */}
                            <Typography variant="body2" component="p">
                              Variant: {item.model_name}
                            </Typography>

                            {/* Price */}
                            <Typography variant="body2" component="p">
                              Price: <NumberFormat value={item.model_discounted_price} displayType={'text'} thousandSeparator={true} />đ
                            </Typography>

                            {/* QR */}
                            <Typography variant="body2" component="p">
                              Đã tạo GTX/PET: {(item.taken_factory_chip > 1) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                              {item.history.gen_GTX == "" ? "": new Date(item.history.gen_GTX*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                            </Typography>

                             {/* Send Factory */}
                             {/* <Typography variant="body2" component="p">
                              Đang sản xuất: {(item.taken_factory_chip > 2) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                              {item.history.send_factory == "" ? "": new Date(item.history.send_factory*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                            </Typography> */}
                            {[ "Mica Custom", "Mica", "Phone Case"].includes(item.print_type) == false ?
                             <Typography variant="body2" component="p">
                              Đang sản xuất: {(item.taken_factory_chip > 2) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                              {item.history.send_factory == "" ? "": new Date(item.history.send_factory*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                            </Typography> :

                            <div>
                              <Typography variant="body2" component="p">
                                Tạo csv: {(item.taken_factory_chip > 2) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                                {item.history.send_factory == "" ? "": new Date(item.history.send_factory*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                              </Typography>

                              <Typography variant="body2" component="p">
                                Đã gửi xưởng: {(item.taken_factory_chip > 3) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                                {item.history.send_factory_done == "" ? "": new Date(item.history.send_factory_done*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                                {item.history.send_factory_name == "" ? "": " ("+item.history.send_factory_name+")"}

                              </Typography>

                            </div>

                            }

                            {/* Taken Factory */}
                            <Typography variant="body2" component="p">
                              Đã nhận xưởng: {(item.taken_factory_chip > 3.5) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                              {item.history.taken_factory == "" ? "": new Date(item.history.taken_factory*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                            </Typography>

                            {/* Send Carrier */}
                            <Typography variant="body2" component="p">
                              Đã gửi bưu cục: {(item.taken_factory_chip > 4) ? <CheckBoxIcon color="success"/> : <CheckBoxOutlineBlankIcon />}
                              {item.history.send_carrier == "" ? "": new Date(item.history.send_carrier*1000).toLocaleDateString("en-GB",{ hour: 'numeric',hour12: true ,minute: 'numeric'})}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default function PreviewTable(props_main) {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: "90vh" }}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="left"><Checkbox {...label} /></TableCell>
            <TableCell align="center">Order ID</TableCell>
            <TableCell align="center">Shop</TableCell>
            <TableCell align="center">Status</TableCell>
            <TableCell align="center">Created</TableCell>
            <TableCell align="center">Deadline</TableCell>
            <TableCell align="center">Shipping Label</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props_main.loadedListCard.map((row) => (
          // {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
