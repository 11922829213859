import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';


import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import { disable } from 'workbox-navigation-preload';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

function Row(props) {
  const { row } = props;

  return (
    <React.Fragment>
      <TableRow 
        style={{
          backgroundColor: row.taken_factory > 3 ? 'rgb(192 255 213 / 65%)' : '',
        }}
      >
        <TableCell
            align="center"
            component="img"
            src={row.image}
            sx={{
              height: "200px",
              width: "200px",
              marginRight: "auto",
              marginLeft: "auto",
              p:"4%"
            }}
          >
        </TableCell>
        
        <TableCell align="center">{row.variant}</TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function PreviewTable(props_main) {
  return (
    // maxHeight: "90vh",
    <TableContainer component={Paper} sx={{  marginTop:"3%" }} xl={12} lg={12} md={12} sm={12} xs={12}>
      <Table stickyHeader aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell align="center">Preview</TableCell>
            <TableCell align="center">Variant</TableCell>
            {/* <TableCell align="center">Shop</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {props_main.loadedListCard.map((row) => (
          // {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
